import "./HomePage.css";
import PageContainer from "./PageContainer";

function HomePage() {
  return (
    <PageContainer className="home">
      <h1 className="text-center title">Kare Nuorteva's Homepage</h1>
    </PageContainer>
  );
}

export default HomePage;
