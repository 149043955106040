import "./App.css";
import Router from "./pages/Router";
import { useGoogleAnalytics } from "./hooks/useGoogleAnalytics";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_VERSION,
  environment: process.env.NODE_ENV,
  debug: (process.env.NODE_ENV === "development"),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

function App() {
  useGoogleAnalytics();

  return (
    <Router />
  );
}

export default App;
