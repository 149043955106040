import React from 'react';

function year(): number {
  return new Date().getFullYear();
}

function version(): string {
  const v = process.env.REACT_APP_VERSION;
  if (v === undefined) {
    return "unknown";
  }
  return v;
}

function Footer() {
  return (
    <footer className="text-center bg-dark text-white">
      Made with <span role="img" aria-label="love">❤️</span> by Kare Nuorteva <span title={version()}>{year()}</span>
    </footer>
  );
}

export default Footer;
