import "./AboutPage.css";
import PageContainer from "./PageContainer";

function AboutPage() {
  const cssStyles = {
    height: '90vh',
  };
  return (
    <PageContainer className="about">
      <div style={cssStyles}>
        <h1>About Kare Nuorteva</h1>
        <p>I'm a freelancing hands-on software architect/developer specialized in <a href="https://golang.org">Go programming language</a> and a <a href="https://en.wikipedia.org/wiki/Plan_9_from_Bell_Labs">Plan 9</a> hobbyist.</p>
      </div>
    </PageContainer>
  );
}

export default AboutPage;
