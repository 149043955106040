import React from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import {
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
} from "reactstrap";
import {
  faBlog,
  faCircleInfo,
  faHashtag,
  faHouse,
  faLaptopCode,
  faMobileRetro,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Navigation.css";

function MenuItem(props: any) {
  return (
    <NavItem>
      <RouterNavLink to={props.to} className="nav-link">
        {props.children}
      </RouterNavLink>
    </NavItem>
  );
}

function ExternalMenuItem(props: any) {
  return (
    <NavItem>
      <a href={props.href} className="nav-link">
        {props.children}
      </a>
    </NavItem>
  );
}

function Navigation(args: any) {
  return (
    <Navbar {...args} container="fluid" expand>
      <NavbarBrand href="/">Kare Nuorteva</NavbarBrand>
      <Nav className="me-auto" navbar>
        <MenuItem to="/" alt="Home" exact>Home <FontAwesomeIcon icon={faHouse} /></MenuItem>
        <MenuItem to="/about" alt="About">About <FontAwesomeIcon icon={faCircleInfo} /></MenuItem>
        <MenuItem to="/contact" alt="Contact">Contact <FontAwesomeIcon icon={faMobileRetro} /></MenuItem>
        <ExternalMenuItem href="https://blog.karenuorteva.fi">Blog <FontAwesomeIcon icon={faBlog} /></ExternalMenuItem>
        <ExternalMenuItem href="https://github.com/kare">GitHub <FontAwesomeIcon icon={faLaptopCode} /></ExternalMenuItem>
        <ExternalMenuItem href="https://twitter.com/KareNuorteva">Twitter <FontAwesomeIcon icon={faHashtag} /></ExternalMenuItem>
      </Nav>
    </Navbar>
  );
}

export default Navigation;

