import "./ContactPage.css";
import PageContainer from "./PageContainer";

function ContactPage() {
  return (
    <PageContainer className="contact text-center">
      <h1 className="title-for-google">Contact</h1>
      <h2 className="title">
        <a href="mailto:kare.nuorteva@me.com">kare.nuorteva@me.com</a>
      </h2>
      <h2 className="title">
        <a href="tel:+358408619280">+358 40 861 9280</a>
      </h2>
    </PageContainer>
  );
}

export default ContactPage;
