import React from "react";
import {
    Col,
    Container,
    Row
} from "reactstrap";

function PageContainer(args: any) {
  return (
    <Container fluid className={'page d-flex flex-column justify-content-center align-items-center ' + args.className}>
      <Row>
        <Col>
          {args.children}
        </Col>
      </Row>
    </Container>
  );
}

export default PageContainer;

