import "./PageNotFound.css";
import PageContainer from "./PageContainer";

function PageNotFound() {
  return (
    <PageContainer>
      <h1 className="text-center title">Page Not Found</h1>
    </PageContainer>
  );
}

export default PageNotFound;
